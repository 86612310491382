import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import BF2042Generator from './generators/BF2042/BF2042Generator';
import Helldivers2Generator from './generators/Helldivers2/Helldivers2Generator';
import HomePage from './components/HomePage';
import OptOutBanner from './components/OptOutBanner';
import { Helmet } from 'react-helmet';

const Tracking = () => {
  const location = useLocation();
  const [isOptedOut, setIsOptedOut] = useState(false);

  useEffect(() => {
    const optOutCookie = document.cookie.split('; ').find(row => row.startsWith('ga-opt-out='));
    if (optOutCookie) {
      setIsOptedOut(optOutCookie.split('=')[1] === 'true');
    }
  }, []);

  useEffect(() => {
    if (window.gtag) {
      window.gtag('config', 'G-GS3WL1HNQR', {
        page_path: location.pathname + location.search,
      });
    }
  }, [location, isOptedOut]);

  return null;
};

function App() {

  const [isOptedOut, setIsOptedOut] = useState(false);

  const handleOptOut = () => {
    document.cookie = 'ga-opt-out=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/';
    setIsOptedOut(true);
  };

  return (
    <Router>
      <Tracking />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Helmet>
                <title>loadoutgenerator.net</title>
                <meta name="description" content="A collection of loadout generators" />
              </Helmet>
              <HomePage />
            </>
          }
        />
        <Route
          path="/bf2042"
          element={
            <>
              <Helmet>
                <title>loadoutgenerator.net - Battlefield 2042 Generator</title>
                <meta name="description" content="Generate random loadouts for Battlefield 2042" />
              </Helmet>
              <BF2042Generator />
            </>
          }
        />
        <Route
          path="/helldivers2"
          element={
            <>
              <Helmet>
                <title>loadoutgenerator.net - HELLDIVERS 2 Generator</title>
                <meta name="description" content="Generate random loadouts for Helldivers 2" />
              </Helmet>
              <Helldivers2Generator />
            </>
          }
        />
      </Routes>
      {!isOptedOut && <OptOutBanner onOptOut={handleOptOut} />}
    </Router>
  );
}

export default App;
