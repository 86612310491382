import React, { useState } from 'react';
import './BF2042Generator.css';
import { Helmet } from 'react-helmet';

const classes = {
  Assault: {
    specialists: ['Sundance', 'Dozer', 'Mackay', 'Zain'],
    gadgets: ['Smoke Grenade Launcher', 'IBA Armor Plate', 'C5 Explosive', 'M18 Claymore', 'SPH Explosive Launcher'],
    throwables: ['Frag Grenade', 'Concussion Grenade', 'Smoke Grenade', 'Incendiary Grenade', 'Throwing Knife', 'Mini Grenade', 'Spring Grenade']
  },
  Engineer: {
    specialists: ['Boris', 'Lis', 'Crawford'],
    gadgets: ['Anti-Tank Mine', 'Recoilless M5', 'FXM-33 AA Missle', 'C5 Explosive', 'FGM-148 Javelin', 'EOD Bot', 'RPG-7V2'],
    throwables: ['Frag Grenade', 'Smoke Grenade', 'EMP Grenade', 'Incendiary Grenade', 'Anti-Tank Grenade', 'Mini Grenade', 'Spring Grenade']
  },
  Support: {
    specialists: ['Irish', 'Falck', 'Angel'],
    gadgets: ['Ammo Crate', 'Medical Crate', 'Smoke Grenade Launcher', 'M18 Claymore', 'Ammo Pouch', 'Medical Pouch'],
    throwables: ['Frag Grenade', 'Concussion Grenade', 'Smoke Grenade', 'EMP Grenade', 'Incendiary Grenade', 'Mini Grenade', 'Spring Grenade']
  },
  Recon: {
    specialists: ['Casper', 'Rao', 'Paik', 'Blasco'],
    gadgets: ['T-UGS', 'Tracer Dart Gun', 'C5 Explosive', 'M18 Claymore', 'Prox Sensor', 'SOFLAM'],
    throwables: ['Frag Grenade', 'Concussion Grenade', 'Smoke Grenade', 'EMP Grenade', 'Incendiary Grenade', 'Throwing Knife', 'Mini Grenade', 'Spring Grenade']
  }
};

const primaryWeapons = [
  'PBX-45',
  'PP-29',
  'MP9',
  'K30',
  'AC9',
  'P90',
  'AKS-74u',
  'PP-2000',
  'M5A3',
  'AM40',
  'AK-24',
  'SFAR-M GL',
  'AC-42',
  'RM68',
  'GEW-46',
  'VHX-D3',
  'M16A3',
  'ACW-R',
  'A-91',
  'M416',
  'MTAR-21',
  'AEK-971',
  'LCMG',
  'Avancys',
  'PKP-BP',
  'RPT-31',
  'M60E4',
  'XM8 LMG',
  'M240B',
  'Type 88 LMG',
  'RPK-74M',
  'DM7',
  'SVK',
  'VCAR',
  'BSV-M',
  'G428',
  'M39 EMR',
  'SVD',
  'SWS-10',
  'DXR-1',
  'NTW-50',
  'XCE BAR',
  'GOL Sniper Magnum',
  'MCS-880',
  'GVT 45-70',
  '12M Auto',
  'Ghostmaker R10',
  'Rorsch MK-4',
  'NVK-S22'
];

const secondaryWeapons = [
  'G57',
  'MP28',
  'M44',
  'PF51',
  'NVK-P125',
  'Super 500',
  'L9CZ',
  'BFP .50',
  'M1911',
  'MP412 REX',
  'M93R',
  'MP443'
]

const BF2042Generator = () => {
  const [selectedClassName, setSelectedClassName] = useState(null);
  const [selectedSpecialist, setSelectedSpecialist] = useState(null);
  const [selectedPrimary, setSelectedPrimary] = useState(null);
  const [selectedSecondary, setSelectedSecondary] = useState(null);
  const [selectedGadget, setSelectedGadget] = useState(null);
  const [selectedThrowable, setSelectedThrowable] = useState(null);

  const handleGenerateLoadout = () => {
    // Randomly select class
    const classNames = Object.keys(classes);
    const randomClassName = classNames[Math.floor(Math.random() * classNames.length)];

    // Get the class object using the class name
    const randomClass = classes[randomClassName];

    // Randomly select specialist within class
    const randomSpecialist = randomClass.specialists[Math.floor(Math.random() * randomClass.specialists.length)];

    // Filter out prohibited gadgets for Lis
    let availableGadgets = randomClass.gadgets;
    if (randomSpecialist === 'Lis') {
      availableGadgets = availableGadgets.filter(gadget => ![
        'Recoilless M5',
        'FXM-33 AA Missle',
        'FGM-148 Javelin',
        'RPG-7V2'
      ].includes(gadget));
    }

    // Randomly select primary weapon
    const randomPrimary = primaryWeapons[Math.floor(Math.random() * primaryWeapons.length)];

    // Randomly select secondary weapon
    const randomSecondary = secondaryWeapons[Math.floor(Math.random() * secondaryWeapons.length)];

    // Randomly select gadget within class
    const randomGadget = availableGadgets[Math.floor(Math.random() * availableGadgets.length)];

    // Randomly select throwable within class
    const randomThrowable = randomClass.throwables[Math.floor(Math.random() * randomClass.throwables.length)];

    // Update state with randomly selected loadout
    setSelectedClassName(randomClassName);
    setSelectedSpecialist(randomSpecialist);
    setSelectedPrimary(randomPrimary);
    setSelectedSecondary(randomSecondary);
    setSelectedGadget(randomGadget);
    setSelectedThrowable(randomThrowable);
  };

  return (
    <div className="container">
      <Helmet>
        <title>Battlefield 2042 Loadout Generator</title>
        <meta name="description" content="Generate random loadouts for Battlefield 2042." />
      </Helmet>

      <h1>BF2042 Random Loadout Generator</h1>
      <button className="button" onClick={handleGenerateLoadout}>Generate Loadout</button>

      {selectedClassName && (
        <div className="loadout">
        <div className="layout">
          <div className="operator-portrait" style={{ backgroundImage: `url('https://media.contentapi.ea.com/content/dam/battlefield/battlefield-2042/specialists/common/classes/bf-2042-section-bg-specialists-sundance-xl.jpg.adapt.320w.jpg')` }}>
            <div className="class-name">{selectedClassName}</div>
            <div className="operator-name">{selectedSpecialist}</div>
          </div>
          <div className="primary">{selectedPrimary}</div>
          <div className="secondary">{selectedSecondary}</div>
          <div className="gadget">{selectedGadget}</div>
          <div className="throwable">{selectedThrowable}</div>
        </div>
      </div>
      )}
    </div>
  );
};

export default BF2042Generator;