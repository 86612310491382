import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import './HomePage.css';

const HomePage = () => {
  return (
    <div className="homepage-container">
      <Helmet>
        <title>Loadout Generators - Home</title>
        <meta name="description" content="Choose from a list of loadout generators for various games." />
      </Helmet>
      
      <h1 className="homepage-title">Choose a Loadout Generator</h1>
      <ul className="generator-list">
        <li>
          <Link to="/bf2042" className="generator-item">
            <div className="generator-card-bf2042"></div>
            <span className="generator-title">Battlefield 2042</span>
          </Link>
        </li>
        <li>
        <Link to="/helldivers2" className="generator-item">
            <div className="generator-card-helldivers2"></div>
            <span className="generator-title">Helldivers 2</span>
        </Link>
        </li>
      </ul>
    </div>
  );
};

export default HomePage;
