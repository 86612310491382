import React, { useState } from "react";
import "./Helldivers2Generator.css";
import { Helmet } from "react-helmet";

const primaryWeapons = [
  "AR23-Liberator",
  "AR-23P Liberator Penetrator",
  "AR-23C Liberator Concussive",
  "R-63 Diligence",
  "R-63CS Diligence Counter Sniper",
  "SMG-37 Defender",
  "MP-98 Knight",
  "SG-8 Punisher",
  "SG-225 Breaker",
  "SG-8S Slugger",
  "SG-225SP Breaker Spray and Pray",
  "SG-225IE Breaker Incendiary",
  "LAS-5 Scythe",
  "PLAS-1 Scorcher",
  "JAR-5 Dominator",
  "LAS-16 Sickle",
  "SG-8P Punisher Plasma",
  "ARC-12 Blitzer",
  "BR-14 Adjudicator",
  "CB-9 Exploding Crossbow",
  "R-36 Eruptor"
];

const secondaryWeapons = [
  "P-2 Peacemaker",
  "P-19 Redeemer",
  "P-4 Senator",
  "LAS-7 Dagger",
  "GP-31 Grenade Pistol"
];

const grenades = [
  "G-12 High Explosive",
  "G-6 Frag",
  "G-16 Impact",
  "G-3 Smoke",
  "G-10 Incendiary",
  "G-23 Stun",
  "G-123 Thermite",
];

const stratagems = [
  {
    name: 'AX/LAS-5 "Guard Dog" Rover',
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Engineering Bay/Guard Dog Rover.svg",
  },
  {
    name: 'AX/AR-23 "Guard Dog"',
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Robotics Workshop/Guard Dog.svg",
  },
  {
    name: "LIFT-850 Jump Pack",
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Hangar/Jump Pack.svg",
  },
  {
    name: "B-1 Supply Pack",
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Engineering Bay/Supply Pack.svg",
  },
  {
    name: "SH-32 Shield Generator Pack",
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Engineering Bay/Shield Generator Pack.svg",
  },
  {
    name: "SH-20 Ballistic Shield Backpack",
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Engineering Bay/Ballistic Shield Backpack.svg",
  },
  {
    name: "AC-8 Autocannon",
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Patriotic Administration Center/Autocannon.svg",
  },
  {
    name: "EAT-17 Expendable Anti-Tank",
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Patriotic Administration Center/Expendable Anti-Tank.svg",
  },
  {
    name: "FLAM-40 Flamethrower",
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Patriotic Administration Center/Flamethrower.svg",
  },
  {
    name: "LAS-98 Laser Cannon",
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Engineering Bay/Laser Cannon.svg",
  },
  {
    name: "M-105 Stalwart",
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Patriotic Administration Center/Stalwart.svg",
  },
  {
    name: "MG-43 Machine Gun",
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Patriotic Administration Center/Machine Gun.svg",
  },
  {
    name: "ARC-3 Arc Thrower",
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Engineering Bay/Arc Thrower.svg",
  },
  {
    name: "GL-21 Grenade Launcher",
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Engineering Bay/Grenade Launcher.svg",
  },
  {
    name: "APW-1 Anti-Materiel Rifle",
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Patriotic Administration Center/Anti-Materiel Rifle.svg",
  },
  {
    name: "RS-422 Railgun",
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Patriotic Administration Center/Railgun.svg",
  },
  {
    name: "GR-8 Recoilless Rifle",
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Patriotic Administration Center/Recoilless Rifle.svg",
  },
  {
    name: "FAF-14 SPEAR",
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Patriotic Administration Center/Spear.svg",
  },
  {
    name: "A/ARC-3 Tesla Tower",
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Bridge/Tesla Tower.svg",
  },
  {
    name: "A/M-12 Mortar Sentry",
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Robotics Workshop/Mortar Sentry.svg",
  },
  {
    name: "A/M-23 EMS Mortar Sentry",
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Robotics Workshop/EMS Mortar Sentry.svg",
  },
  {
    name: "A/MG-43 Machine Gun Sentry",
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Robotics Workshop/Machine Gun Sentry.svg",
  },
  {
    name: "A/G-16 Gatling Sentry",
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Robotics Workshop/Gatling Sentry.svg",
  },
  {
    name: "MD-6 Anti-Personnel Minefield",
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Engineering Bay/Anti-Personnel Minefield.svg",
  },
  {
    name: "MD-I4 Incendiary Mines",
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Engineering Bay/Incendiary Mines.svg",
  },
  {
    name: "FX-12 Shield Generator Relay",
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Bridge/Shield Generator Relay.svg",
  },
  {
    name: "E/MG-101 HMG Emplacement",
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Bridge/HMG Emplacement.svg",
  },
  {
    name: "A/AC-8 Autocannon Sentry",
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Robotics Workshop/Autocannon Sentry.svg",
  },
  {
    name: "A/MLS-4X Rocket Sentry",
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Robotics Workshop/Rocket Sentry.svg",
  },
  {
    name: "Orbital Precision Strike",
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Bridge/Orbital Precision Strike.svg",
  },
  {
    name: "Orbital Airbust Strike",
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Orbital Cannons/Orbital Airburst Strike.svg",
  },
  {
    name: "Orbital 120MM HE Barrage",
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Orbital Cannons/Orbital 120MM HE Barrage.svg",
  },
  {
    name: "Orbital 380MM HE Barrage",
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Orbital Cannons/Orbital 380MM HE Barrage.svg",
  },
  {
    name: "Orbital Walking Barrage",
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Orbital Cannons/Orbital Walking Barrage.svg",
  },
  {
    name: "Orbital Laser",
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Orbital Cannons/Orbital Laser.svg",
  },
  {
    name: "Orbital Railcannon Strike",
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Orbital Cannons/Orbital Railcannon Strike.svg",
  },
  {
    name: "Orbital Gatling Barrage",
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Orbital Cannons/Orbital Gatling Barrage.svg",
  },
  {
    name: "Orbital Gas Strike",
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Bridge/Orbital Gas Strike.svg",
  },
  {
    name: "Orbital EMS Strike",
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Bridge/Orbital EMS Strike.svg",
  },
  {
    name: "Orbital Smoke Strike",
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Bridge/Orbital Smoke Strike.svg",
  },
  {
    name: "Eagle Strafing Run",
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Hangar/Eagle Strafing Run.svg",
  },
  {
    name: "Eagle Airstrike",
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Hangar/Eagle Airstrike.svg",
  },
  {
    name: "Eagle Cluster Bomb",
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Hangar/Eagle Cluster Bomb.svg",
  },
  {
    name: "Eagle Napalm Strike",
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Hangar/Eagle Napalm Airstrike.svg",
  },
  {
    name: "Eagle Smoke Strike",
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Hangar/Eagle Smoke Strike.svg",
  },
  {
    name: "Eagle 110MM Rocket Pods",
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Hangar/Eagle 110MM Rocket Pods.svg",
  },
  {
    name: "Eagle 500kg Bomb",
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Hangar/Eagle 500KG Bomb.svg",
  },
  {
    name: "EXO-45 Patriot Exosuit",
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Robotics Workshop/Patriot Exosuit.svg",
  },
  {
    name: "MG-206 Heavy Machine Gun",
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Patriotic Administration Center/Heavy Machine Gun.svg",
  },
  {
    name: "LAS-99 Quasar Cannon",
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Engineering Bay/Quasar Cannon.svg",
  },
  {
    name: "RL-77 Airburst Rocket Launcher",
    icon: 'assets/icons/Helldivers-2-Stratagems-icons-svg/Patriotic Administration Center/Airburst Rocket Launcher.svg'
  }
];

const boosters = [
  "Hellpod Space Optimization",
  "Vitality Enhancement",
  "UAV Recon Booster",
  "Stamina Enhancement",
  "Muscle Enhancement",
  "Increased Reinforcement Budget",
  "Flexible Reinforcement Budget",
  "Localization Confusion",
];

const Helldivers2Generator = () => {
  const [selectedPrimary, setSelectedPrimary] = useState(null);
  const [selectedSecondary, setSelectedSecondary] = useState(null);
  const [selectedGrenade, setSelectedGrenade] = useState(null);
  const [selectedStratagems, setSelectedStratagems] = useState([]);
  const [selectedBooster, setSelectedBooster] = useState([]);

  const handleGenerateLoadout = () => {
    // Randomly select primary weapon
    const randomPrimary =
      primaryWeapons[Math.floor(Math.random() * primaryWeapons.length)];

    // Randomly select secondary weapon
    const randomSecondary =
      secondaryWeapons[Math.floor(Math.random() * secondaryWeapons.length)];

    // Randomly select grenade
    const randomGrenade = grenades[Math.floor(Math.random() * grenades.length)];

    // Randomly select 4 unique stratagems
    const randomStratagems = [];
    while (randomStratagems.length < 4) {
      const stratagem =
        stratagems[Math.floor(Math.random() * stratagems.length)];
      if (!randomStratagems.includes(stratagem)) {
        randomStratagems.push({ name: stratagem.name, icon: stratagem.icon });
      }
    }

    // Randomly select booster
    const randomBooster = boosters[Math.floor(Math.random() * boosters.length)];

    setSelectedPrimary(randomPrimary);
    setSelectedSecondary(randomSecondary);
    setSelectedGrenade(randomGrenade);
    setSelectedStratagems(randomStratagems);
    setSelectedBooster(randomBooster);
  };

  return (
    <div className="container">
      <Helmet>
        <title>HELLDIVERS 2 Loadout Generator</title>
        <meta
          name="description"
          content="Generate random loadouts for Helldivers 2."
        />
      </Helmet>

      <div className="container">
        <h1>HELLDIVERS 2 Random Loadout Generator</h1>
        <button className="button" onClick={handleGenerateLoadout}>
          Generate Loadout
        </button>
      </div>
      <div className="loadout">
        <div className="loadout-item">
          <h3>Primary Weapon</h3>
          <p>{selectedPrimary}</p>
        </div>
        <div className="loadout-item">
          <h3>Secondary Weapon</h3>
          <p>{selectedSecondary}</p>
        </div>
        <div className="loadout-item">
          <h3>Grenade</h3>
          <p>{selectedGrenade}</p>
        </div>
        <div className="loadout-item">
          <h3>Stratagems</h3>
          {selectedStratagems.map((stratagem, index) => (
            <div key={index}>
              <p>{stratagem.name}</p>
              <img src={stratagem.icon} alt={stratagem.name} />
            </div>
          ))}
        </div>
        <div className="loadout-item">
          <h3>Booster</h3>
          <p>{selectedBooster}</p>
        </div>
      </div>
      <footer className="footer">
        <p>
          <strong>Coming Soon:</strong> Loadout saving
        </p>
        <p>Updated 5/4/2024 - Added new weapons/stratagems, converted to dark mode, and added stratagem icons!</p>
      </footer>
    </div>
  );
};

export default Helldivers2Generator;
